@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
	font: normal 1.125rem "Abel", sans-serif;
}

.card {
	width: 30%;
	margin: 0 auto;
	border-radius: 10px;
	padding: 20px;
	box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.15);
}

.card-content {
	width: 80%;
	margin: 0 auto;
	padding: 10px;
}

.logo {
	height: 5vmin;
	pointer-events: none;
}

.text-center {
	text-align: center;
}

.avatar {
	border-radius: 100%;
	width: 3rem;
	height: 3rem;
}

.flex {
	display: flex;
}

.items-center {
	align-items: center;
}

.justify-center{
	justify-content: center;
}

.gap-2 {
	grid-gap: 0.5rem;
	gap: 0.5rem;
}

.mb-2 {
	margin-bottom: 1rem;
}

.mb-4 {
	margin-bottom: 1.5rem;
}

.pb-2 {
	padding-bottom: 1rem;
}

.pb-4 {
	padding-bottom: 1.5rem;
}

.pb-6 {
	padding-bottom: 2.5rem;
}

.font-medium {
	font-weight: 600;
}

.h5 {
	font-size: 1.25rem;
	font-weight: 600;
}

.h4 {
	font-size: 1.5rem;
	font-weight: 600;
}

.text-gray {
	color: dimgray;
}

.text-lightGray{
	color: #bbb;
}

.text-green{
	color: forestgreen;
}

.clearfix {
	clear: both;
}

.list-unstyled{
	list-style: none;
	padding: 0;
}

ul li{
	padding: 4px;
}

.border-dashed-top{
	border-top: 1px dashed #ccc;
}

.border-dashed-bottom{
	border-bottom: 1px dashed #ccc;
}
.py-2{
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.btn{
	border-radius: 6px;
	padding: 12px;
	color: #646464;
	font-weight: 600;
	outline: none;
	border: none;
	box-shadow: 0 2px 4px 0px #333;
}

.btnPay{
	background: midnightblue;
	color: #fff!important;
	cursor: pointer;
}

.btnPay:hover{
	background:slateblue;
	color: #fff!important;
}

.h-screen{
	height: 100vh;
}

.h3{
	font-size: 2.0rem;
	font-weight: 600;
}

.text-blue{
	color: navy;
}

@media screen and (max-width: 768px) {
	.logo {
		height: 10vmin;
	}
	
	.card {
		width: 80%;
	}
	.card-content {
		width: 100%;
	}

	.h5 {
		font-size: 1rem;
	}

	.h4 {
		font-size: 1.25rem;
	}

	.h3{
		font-size: 1.75rem;
	}

	body{
		font-size: 1rem;
	}
}

